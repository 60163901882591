import React from "react"
import { Text } from "./text"
import { Box } from "./box"
import styled from "styled-components"

const Container = styled(Box)``

const InputE = styled.input`
  /* background-color: #dddddd; */
  border: 1px solid ${props => props.theme.colors.border};
  color: inherit;
  box-shadow: none;
  border-radius: ${props => props.borderRadius};
  background-color: transparent;
  padding: ${props => props.padding};
  width: 100%;
  font-size: ${props => props.fontSize};
  &:focus {
    outline: none;
  }
`

export const Input = props => {
  return (
    <Container width={props.width}>
      <InputE
        {...props}
        id={props.name}
        placeholder={props.placeholder}
        fontSize={props.fontSize}
        padding={props.padding}
        borderRadius={props.borderRadius}
      />
      {props.error && <Text>{props.error}</Text>}
    </Container>
  )
}

Input.defaultProps = {
  width: "auto",
  padding: "5px 10px",
  borderRadius: "5px",
}
