import { createReducer } from "../reducerUtil"
import {
  UPDATE_COLORS,
  UPDATE_SOUND,
  UPDATE_EFFECT,
  UPDATE_PRONUNCIATION,
  UPDATE_BACKGROUND_COLOR,
  UPDATE_ALL_GENERAL,
  UPDATE_COLOR_MODE,
} from "./constants"

const initialState = {
  colors: {
    array: ["#59569D", "#F25292"],
    index: 0,
  },
  colorMode: "Preset", // Preset | Custom
  sound: {
    on: true,
  },
  effect: {
    on: true,
  },
  pronunciation: {
    on: false,
  },
  backgroundColor: "#917FB3",
  websiteMode: "Normal",
}

export const updateColors = (state, payload) => {
  return {
    ...state,
    colors: {
      array: payload.colors,
      index: payload.index,
    },
  }
}

export const updateSound = (state, payload) => {
  return {
    ...state,
    sound: {
      on: payload.on,
    },
  }
}

export const updateEffect = (state, payload) => {
  return {
    ...state,
    effect: {
      on: payload.on,
    },
  }
}

export const updatePronunciation = (state, payload) => {
  return {
    ...state,
    pronunciation: {
      on: payload.on,
    },
  }
}

export const updateBackgroundColor = (state, payload) => {
  return {
    ...state,
    backgroundColor: payload.backgroundColor,
  }
}

export const updateAllGeneral = (state, payload) => {
  switch (payload.page) {
    case "tarot":
      return {
        ...state,
        colors: payload.settings.colors,
        backgroundColor: payload.settings.backgroundColor,
      }
    case "number":
      return {
        ...state,
        colors: payload.settings.colors,
        backgroundColor: payload.settings.backgroundColor,
        sound: payload.settings.sound,
        effect: payload.settings.effect,
      }
    default:
      return {
        ...state,
        backgroundColor: payload.settings.backgroundColor,
        sound: payload.settings.sound,
      }
  }
}

export const updateColorMode = (state, payload) => {
  return {
    ...state,
    colorMode: payload.mode,
  }
}

export default createReducer(initialState, {
  [UPDATE_COLORS]: updateColors,
  [UPDATE_SOUND]: updateSound,
  [UPDATE_EFFECT]: updateEffect,
  [UPDATE_PRONUNCIATION]: updatePronunciation,
  [UPDATE_BACKGROUND_COLOR]: updateBackgroundColor,
  [UPDATE_ALL_GENERAL]: updateAllGeneral,
  [UPDATE_COLOR_MODE]: updateColorMode,
})
