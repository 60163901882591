import { createReducer } from "../reducerUtil"
import {
  UPDATE_TAROT_TYPE,
  UPDATE_TAROT_TEXT,
  UPDATE_TAROT_QUESTION,
  UPDATE_ALL_TAROT_SETTINGS,
} from "./constants"

const initialState = {
  type: "Yes or No or Maybe",
  text: "",
  question: {
    on: true,
  },
}

export const updateTarotType = (state, payload) => {
  return {
    ...state,
    type: payload.type,
  }
}

export const updateTarotText = (state, payload) => {
  return {
    ...state,
    text: payload.text,
  }
}

export const updateTarotQuestion = (state, payload) => {
  return {
    ...state,
    question: {
      on: payload.on,
    },
  }
}

export const updateAllTarotSettings = (state, payload) => {
  switch (payload.page) {
    case "tarot":
      return {
        ...state,
        text: payload.settings.data.text,
        question: payload.settings.data.question,
      }
  }
}

export default createReducer(initialState, {
  [UPDATE_TAROT_TYPE]: updateTarotType,
  [UPDATE_TAROT_TEXT]: updateTarotText,
  [UPDATE_TAROT_QUESTION]: updateTarotQuestion,
  [UPDATE_ALL_TAROT_SETTINGS]: updateAllTarotSettings,
})
