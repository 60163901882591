import { KEEP_SHARE_LINK } from "./constants"
import { createReducer } from "../reducerUtil"

const initialState = {
  createdCount: 0,
  lastCreate: 0,
  lastSharePage: null,
  settings: null,
  path: "",
}

export const keepShareLink = (state, payload) => {
  return {
    lastSharePage: payload.sharePage,
    lastCreate: payload.lastCreate,
    settings: payload.settings,
    createdCount: payload.createdCount,
    path: payload.path,
  }
}

export default createReducer(initialState, {
  [KEEP_SHARE_LINK]: keepShareLink,
})
