import React from "react"
import styled, { css } from "styled-components"
import { desaturate } from "polished"

const ButtonStyle = css`
  align-self: ${props => props.alignSelf};
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  color: ${props => props.theme.colors[props.color]};
  width: ${props => props.width};
  padding: ${props => props.padding};
  /* border: none; */
  text-transform: uppercase;
  border: ${props => props.borderWidth} solid ${props =>
    props.theme.colors[props.color]};
  box-shadow: none;
  font-weight: 700;
  border-radius: ${props => props.borderRadius};
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  text-align: center;
  line-height: 1.4;
  font-size: ${props => props.fontSize};
  /* &:hover {
    background-color: ${props => desaturate(0.05, props.color)};
    color: white;
    border: 2px solid ${props => desaturate(0.05, props.color)};
  } */
`

const AnchorT = styled.a`
  ${ButtonStyle}
  text-decoration: none;
  line-height: 1.4;
`

const ButtonT = styled.button`
  ${ButtonStyle}
`

const TextButton = styled.span`
  align-self: ${props => props.alignSelf};
  color: ${props => props.theme.colors[props.color]};
  width: ${props => props.width};
  /* border: none; */
  text-transform: uppercase;
  box-shadow: none;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  text-align: center;
  line-height: 1.4;
  font-size: ${props => props.fontSize};
`

export const Button = props => {
  let button = null
  // console.log(props)
  if (props.onClick || props.type === "submit") {
    // console.log("type")
    if (props.pattern === "text") {
      button = (
        <TextButton {...props} onClick={props.onClick}>
          {props.children}
        </TextButton>
      )
    } else {
      button = (
        <ButtonT {...props} onClick={props.onClick}>
          {props.children}
        </ButtonT>
      )
    }
  }

  if (props.url) {
    // console.log("url")
    button = (
      <AnchorT {...props} href={props.url}>
        {props.children}
      </AnchorT>
    )
  }
  return <React.Fragment>{button}</React.Fragment>
}

Button.defaultProps = {
  backgroundColor: "text",
  color: `white`,
  borderRadius: `5px`,
  borderWidth: "0px",
  fontSize: "1.6rem",
  padding: "12px 40px",
}
