exports.components = {
  "component---src-pages-2048-coin-js": () => import("./../../../src/pages/2048-coin.js" /* webpackChunkName: "component---src-pages-2048-coin-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dice-roller-index-js": () => import("./../../../src/pages/dice-roller/index.js" /* webpackChunkName: "component---src-pages-dice-roller-index-js" */),
  "component---src-pages-dice-roller-roll-d-10-js": () => import("./../../../src/pages/dice-roller/roll-d10.js" /* webpackChunkName: "component---src-pages-dice-roller-roll-d-10-js" */),
  "component---src-pages-dice-roller-roll-d-12-js": () => import("./../../../src/pages/dice-roller/roll-d12.js" /* webpackChunkName: "component---src-pages-dice-roller-roll-d-12-js" */),
  "component---src-pages-dice-roller-roll-d-20-js": () => import("./../../../src/pages/dice-roller/roll-d20.js" /* webpackChunkName: "component---src-pages-dice-roller-roll-d-20-js" */),
  "component---src-pages-dice-roller-roll-d-4-js": () => import("./../../../src/pages/dice-roller/roll-d4.js" /* webpackChunkName: "component---src-pages-dice-roller-roll-d-4-js" */),
  "component---src-pages-dice-roller-roll-d-8-js": () => import("./../../../src/pages/dice-roller/roll-d8.js" /* webpackChunkName: "component---src-pages-dice-roller-roll-d-8-js" */),
  "component---src-pages-emb-coin-js": () => import("./../../../src/pages/emb/coin.js" /* webpackChunkName: "component---src-pages-emb-coin-js" */),
  "component---src-pages-emb-d-10-js": () => import("./../../../src/pages/emb/d10.js" /* webpackChunkName: "component---src-pages-emb-d-10-js" */),
  "component---src-pages-emb-d-12-js": () => import("./../../../src/pages/emb/d12.js" /* webpackChunkName: "component---src-pages-emb-d-12-js" */),
  "component---src-pages-emb-d-20-js": () => import("./../../../src/pages/emb/d20.js" /* webpackChunkName: "component---src-pages-emb-d-20-js" */),
  "component---src-pages-emb-d-4-js": () => import("./../../../src/pages/emb/d4.js" /* webpackChunkName: "component---src-pages-emb-d-4-js" */),
  "component---src-pages-emb-d-8-js": () => import("./../../../src/pages/emb/d8.js" /* webpackChunkName: "component---src-pages-emb-d-8-js" */),
  "component---src-pages-emb-dice-js": () => import("./../../../src/pages/emb/dice.js" /* webpackChunkName: "component---src-pages-emb-dice-js" */),
  "component---src-pages-embed-tutorial-js": () => import("./../../../src/pages/embed-tutorial.js" /* webpackChunkName: "component---src-pages-embed-tutorial-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intuition-test-js": () => import("./../../../src/pages/intuition-test.js" /* webpackChunkName: "component---src-pages-intuition-test-js" */),
  "component---src-pages-latest-updates-js": () => import("./../../../src/pages/latest-updates.js" /* webpackChunkName: "component---src-pages-latest-updates-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-luck-test-js": () => import("./../../../src/pages/luck-test.js" /* webpackChunkName: "component---src-pages-luck-test-js" */),
  "component---src-pages-number-cards-js": () => import("./../../../src/pages/number-cards.js" /* webpackChunkName: "component---src-pages-number-cards-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-yes-or-no-tarot-js": () => import("./../../../src/pages/yes-or-no-tarot.js" /* webpackChunkName: "component---src-pages-yes-or-no-tarot-js" */)
}

