/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// import { useStaticQuery, graphql } from "gatsby"
// import { GlobalStyle } from "../theme/globalStyle"
import { Box } from "../components/commonElements"
// import Toast from "../components/global/toast"
// import { useToast } from "../components/global/toast/useToast"
import { ToastProvider } from "../components/contextHook/toast"
import { PageLoadingProvider } from "../components/contextHook/pageLoading"
import { LocalContentProvider } from "../components/contextHook/localContent"
import { LocalEntriesProvider } from "../components/contextHook/localEntries"
// import adThriveScript from "../utils/adthriveScript"
// import BgBubbles from "../components/bgBubbles"

const OuterContainer = styled(Box)`
  overflow: hidden;
  z-index: 2;
  flex: 1;
  margin: 0 auto;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  /* position: relative;
  &::before {
    content: " ";
    position: absolute; // instead of background-attachment: ;
    background-attachment: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      100deg,
      ${props => props.toolColors[0]} -10%,
      ${props => props.toolColors[1]} 150%
    );
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
  } */

  /* position: scroll; */
`

// const Background = styled(Box)`
//   /* background: green; */
//   background-image: -webkit-linear-gradient(
//     top left,
//     ${props => props.toolColors[0]} 0%,
//     ${props => props.toolColors[1]} 100%
//   );
//   background-image: -moz-linear-gradient(
//     top left,
//     ${props => props.toolColors[0]} 0%,
//     ${props => props.toolColors[1]} 100%
//   );
//   background-image: -o-linear-gradient(
//     top left,
//     ${props => props.toolColors[0]} 0%,
//     ${props => props.toolColors[1]} 100%
//   );
//   background-image: linear-gradient(
//     to bottom right,
//     ${props => props.toolColors[0]} 0%,
//     ${props => props.toolColors[1]} 100%
//   );

//   position: absolute;
//   /* background-repeat: no-repeat;
//   background-position: -25%; */
//   /* -webkit-transform: translate3d(0, 0, 0); */
//   /* z-index: 1; */
//   /* backface-visibility: visible;
//   -webkit-transform: translate3d(0, 0, 0); */
//   /* background-attachment: scroll; */
//   /* background-attachment: fixed; */
//   /* background-size: cover; */
//   top: -25%;
//   left: 0;
//   width: 100%;
//   height: 150%;
// `

// const Container = styled(Box)`
//   // max-width: ${props => props.theme.containerSize};
//   z-index: 2;
//   /* margin: 0 auto;
//   padding: 0 1rem 1rem;
//   align-items: stretch;
//   justify-content: center;
//   min-height: calc(100vh - 64px); */
// `

// const Main = styled.main`
//   display: flex;
//   justify-content: flex-start;
//   align-items: stretch;
//   flex-direction: column;
//   flex: 1;
// `

// const Footer = styled.footer`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
// `

const Layout = ({ children }) => {
  // useEffect(() => {
  //   if (process.env.GATSBY_BUILD_CONTEXT === "production") {
  //     adThriveScript(window, document)
  //   }
  // }, [])
  return (
    <OuterContainer>
      <PageLoadingProvider>
        <ToastProvider>
          <LocalContentProvider>
            <LocalEntriesProvider>{children}</LocalEntriesProvider>
          </LocalContentProvider>
        </ToastProvider>
      </PageLoadingProvider>
    </OuterContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
