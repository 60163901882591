import { createReducer } from "../reducerUtil"
import { SET_GLOBAL_STATS_DATA, SET_MY_STATS_DATA, SET_STATS_INDEX } from "./constants"

const initialState = {
  global: {
    tails: 0,
    heads: 0,
  },
  my: {
    tails: 0,
    heads: 0,
  },
  statsIndex: 0,
}

export const setGlobalStatsData = (state, payload) => {
  return {
    ...state,
    global: {
      tails: payload.tails,
      heads: payload.heads,
    }
  }
}

export const setMyStatsData = (state, payload) => {
  return {
    ...state,
    my: {
      tails: payload.tails,
      heads: payload.heads,
    }
  }
}

export const setStatsIndex = (state, payload) => {
  return {
    ...state,
    statsIndex: payload.index
  }
}

export default createReducer(initialState, {
  [SET_GLOBAL_STATS_DATA]: setGlobalStatsData,
  [SET_MY_STATS_DATA]: setMyStatsData,
  [SET_STATS_INDEX]: setStatsIndex
})
