import React from "react"
import { Box } from "./box"
import styled from "styled-components"

export const IconBox = styled(Box)`
  cursor: pointer;
  color: ${props => props.theme.colors[props.color] || props.theme.colors.text};
  opacity: ${props => props.opacity};
`

export const IconButton = ({ onClick, icon, color, ...props }) => {
  return (
    <IconBox onClick={onClick} color={color} {...props}>
      {icon}
    </IconBox>
  )
}
