import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import rootReducer from "./rootReducer"
import thunk from "redux-thunk"
// import { persistReducer } from "redux-persist"
// import storage from "redux-persist/lib/storage"
import { verifyAuth } from "../redux/auth/actions"

export const configureStore = preloadedState => {
  const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))

  // const persistedReducer = persistReducer(persistConfig, rootReducer)

  // const store = createStore(persistedReducer, preloadedState, composedEnhancer)

  const store = createStore(rootReducer, preloadedState, composedEnhancer)
  if (typeof window !== "undefined") {
    store.dispatch(verifyAuth())
  }
  // console.log("env", process.env.GATSBY_BUILD_CONTEXT)
  if (process.env.GATSBY_BUILD_CONTEXT !== "production") {
    if (module.hot) {
      module.hot.accept("./rootReducer", () => {
        const newRootReducer = require("./rootReducer").default
        store.replaceReducer(newRootReducer)
      })
    }
  }

  return store
}
