import React, { useState } from "react"
import styled, { css } from "styled-components"
import { isMobile } from "react-device-detect"

/* Wrapping */
const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`

const TooltipTip = styled.div`
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: white;
    background: ${props => props.theme.colors.bgToolTip};
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
    &::before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 6px;
        margin-left: -6px;
    }
    display: ${isMobile ? `none` : `block`};
    ${props => props.direction === "top" && TooltipTipTop}
    ${props => props.direction === "right" && TooltipTipRight}
    ${props => props.direction === "bottom" && TooltipTipBottom}
    ${props => props.direction === "left" && TooltipTipLeft}
    
`

/* Absolute positioning */
const TooltipTipTop = css`
  top: ${props => `calc(${props.margin} * -1)`};
  &::before {
    top: 100%;
    border-top-color: ${props => props.theme.colors.bgToolTip};
  }
`
const TooltipTipRight = css`
  left: ${props => ` calc(100% + ${props.margin})`};
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: -6px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${props => props.theme.colors.bgToolTip};
  }
`
const TooltipTipBottom = css`
  bottom: ${props => `calc(${props.margin} * -1)`};
  &::before {
    bottom: 100%;
    border-bottom-color: ${props => props.theme.colors.bgToolTip};
  }
`

const TooltipTipLeft = css`
  left: auto;
  right: ${props => ` calc(100% + ${props.margin})`};
  top: 50%;
  transform: translateX(0) translateY(-50%);
  &::before {
    left: auto;
    right: -12px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${props => props.theme.colors.bgToolTip};
  }
`

export const Tooltip = ({
  margin = "30px",
  direction = "top",
  content,
  delay,
  children,
}) => {
  let timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay || 100)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <TooltipWrapper
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <TooltipTip direction={direction} margin={margin}>
          {/* Content */}
          {content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  )
}
