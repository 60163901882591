import React from "react"
// import Layout from "./index"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import { theme } from "../theme/globalStyle"

import { persistStore } from "redux-persist"
import { configureStore } from "../redux/configureStore"
import { persistorInit } from "../utils/reduxPersistor"

const store = configureStore()
persistorInit(store)
// persistStore(store)

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    {/* <GlobalStyle /> */}
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </Provider>
)
