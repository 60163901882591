import { createReducer } from "../reducerUtil"
import {
  UPDATE_ALL_SETTINGS,
  SET_STYLE_INDEX,
  UPDATE_TOOL_STATE,
} from "./constants"

const initialState = {
  styleIndex: 0,
  coin: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    texts: [
      {
        heads: "HEADS",
        tails: "TAILS",
      },
    ],
    images: [
      {
        heads: {
          ori: null,
          crop: null,
        },
        tails: {
          ori: null,
          crop: null,
        },
      },
    ],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },
  d6: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    contentMode: ["SHAPES"],
    contents: [{ shapes: "dots" }],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },
  d4: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    contentMode: ["SHAPES"],
    contents: [{ shapes: "numbers" }],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },
  d8: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    contentMode: ["SHAPES"],
    contents: [{ shapes: "numbers" }],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },
  d10: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    contentMode: ["SHAPES"],
    contents: [{ shapes: "numbers" }],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },
  d12: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    contentMode: ["SHAPES"],
    contents: [{ shapes: "numbers" }],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },
  d20: {
    colors: [
      {
        array: ["#59569D", "#F25292"],
        index: 0,
      },
    ],
    contentMode: ["SHAPES"],
    contents: [{ shapes: "numbers" }],
    quantity: [1],
    styleQuantity: 1,
    styleIndexSequence: [0],
  },

  // start of old state
  coinTexts: [
    {
      heads: "HEADS",
      tails: "TAILS",
    },
  ],
  coinImages: [
    {
      heads: {
        ori: null,
        crop: null,
      },
      tails: {
        ori: null,
        crop: null,
      },
    },
  ],
  quantity: {
    coin: 1,
  },
  colors: {
    array: ["#59569D", "#F25292"],
    index: 0,
  },
  // end of old state version 0
}

export const updateToolState = (state, payload) => {
  return {
    ...state,
    [payload.type]: {
      ...state[payload.type],
      [payload.text]: payload.value,
    },
  }
}

export const updateAllSettings = (state, payload) => {
  switch (payload.page) {
    case "coin":
      return {
        ...state,
        [payload.page]: {
          ...state[payload.page],
          texts: payload.settings.data.texts,
          images: payload.settings.data.images,
          quantity: payload.settings.data.quantity,
          styleQuantity: payload.settings.data.styleQuantity,
          colors: payload.settings.data.colors,
          styleIndexSequence: payload.settings.data.styleIndexSequence,
        },
      }
    case "dice":
    case "d4":
    case "d8":
    case "d10":
    case "d12":
    case "d20":
      return {
        ...state,
        [payload.settings.diceType]: {
          ...state[payload.settings.diceType],
          contentMode: payload.settings.data.contentMode,
          contents: payload.settings.data.contents,
          quantity: payload.settings.data.quantity,
          styleQuantity: payload.settings.data.styleQuantity,
          colors: payload.settings.data.colors,
          styleIndexSequence: payload.settings.data.styleIndexSequence,
        },
      }
  }
}

export const setStyleIndex = (state, payload) => {
  return {
    ...state,
    styleIndex: payload.index,
  }
}

export default createReducer(initialState, {
  [UPDATE_ALL_SETTINGS]: updateAllSettings,
  [SET_STYLE_INDEX]: setStyleIndex,
  [UPDATE_TOOL_STATE]: updateToolState,
})
