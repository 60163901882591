import { SIGN_IN_USER, SIGN_OUT_USER } from "./constants"
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  collection,
} from "firebase/firestore"
import { firestore, auth } from "../../utils/firebase"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth"
import getFirebase from "../../utils/firebase"

let emailIdentity = false

export const signInUser = user => {
  return {
    type: SIGN_IN_USER,
    payload: {
      user,
    },
  }
}

export const signOutUser = () => {
  return {
    type: SIGN_OUT_USER,
  }
}

export const verifyAuth = () => {
  return (dispatch, getState) => {
    getFirebase()
    return onAuthStateChanged(auth, async user => {
      if (user) {
        dispatch(signInUser(user))
        if (!emailIdentity) {
          setEmailIdentity(user.email)
          emailIdentity = true
        }
      } else {
        dispatch(signOutUser())
      }
    })
  }
}

export const handleResetPassword = async email => {
  try {
    await sendPasswordResetEmail(auth, email)
    return { errorCode: null }
  } catch (error) {
    return { errorCode: error.code }
  }
}

export const handleSignOut = async () => {
  await signOut(auth)
}

export const handleSignup = async (email, password) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password)
    await createUserAccount(result.user)
    setEmailIdentity(result.user.email)
    return { user: result.user, errorCode: null }
  } catch (error) {
    return { user: null, errorCode: error.code }
  }
}

export const handleSignin = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password)
    await updateUserLastLoginProvider(result.user)
    setEmailIdentity(result.user.email)
    return { user: result.user, errorCode: null }
  } catch (error) {
    return { user: null, errorCode: error.code }
  }
}

export const handleGoogleSignIn = async () => {
  const provider = new GoogleAuthProvider()
  try {
    const result = await signInWithPopup(auth, provider)
    if (isNewUser(result.user)) {
      await createUserAccount(result.user)
    } else {
      await updateUserLastLoginProvider(result.user)
    }
    setEmailIdentity(result.user.email)
    return { user: result.user, errorCode: null }
  } catch (error) {
    return { user: null, errorCode: error.code }
  }
}

const isNewUser = user => {
  return (
    Math.abs(
      Date.parse(user.reloadUserInfo.lastRefreshAt) -
        parseInt(user.metadata.createdAt)
    ) < 1000
  ) //within 1 second
}

const createUserAccount = async user => {
  try {
    const userId = user.uid
    const usersPathRef = doc(collection(firestore, "users"), userId)
    const signInDate = serverTimestamp()
    const userData = {
      uid: userId,
      createdAt: signInDate,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      providerId: user.providerData[0].providerId,
      lastLoginAt: signInDate,
      role: "subscriber",
    }
    await setDoc(usersPathRef, userData)
  } catch (ex) {
    throw ex
  }
}

const updateUserLastLoginProvider = async user => {
  try {
    const userId = user.uid
    const usersPathRef = doc(collection(firestore, "users"), userId)
    await updateDoc(usersPathRef, {
      lastLoginAt: serverTimestamp(),
      providerId: user.providerData[0].providerId, // have to update this, because gmail will replace password login, but wont update firestore provider
    })
  } catch (ex) {
    throw ex
  }
}

const setEmailIdentity = email => {
  if (process.env.GATSBY_BUILD_CONTEXT !== "development") {
    if (window.adthrive === undefined) return
    window.adthrive.cmd.push(function () {
      window.adthrive.identityApi({
        source: "api",
        plainText: email,
      })
    })
  }
}
