import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { Box } from "../commonElements"

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  & > div:first-child {
    height: 100%;
  }
`

const TooltipTip = styled.div`
  position: absolute;
  border-radius: 0.5rem;
  background: #d9d9d9;
  z-index: 100;
  width: ${props => props.width};
  padding: 0.3rem 0.8rem;
  margin-top: 1rem;
  ${props => props.position.right && `right: ${props.position.right};`}
  ${props => props.position.left && `left: ${props.position.left};`}
  @media ${props => props.theme.device.tablet} {
    font-size: 1.4rem;
    margin-top: 0.6rem;
  }
`

export const TooltipInfo = ({
  content,
  children,
  position = {},
  width = "25rem",
}) => {
  const refButton = useRef()
  const refContent = useRef()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return
    const handleClick = event => {
      let buttonClick = true
      let contentClick = true
      if (!refButton.current || !refButton.current.contains(event.target)) {
        buttonClick = false
      }
      if (!refContent.current || !refContent.current.contains(event.target)) {
        contentClick = false
      }
      if (!buttonClick && !contentClick) {
        setShow(false)
      }
    }
    window.addEventListener("mousedown", handleClick)
    window.addEventListener("touchstart", handleClick)
    return () => {
      window.removeEventListener("mousedown", handleClick)
      window.addEventListener("touchstart", handleClick)
    }
  }, [])

  return (
    <TooltipWrapper>
      <Box ref={refButton} onClick={() => setShow(!show)}>
        {children}
      </Box>
      {show && content && (
        <TooltipTip ref={refContent} position={position} width={width}>
          {content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  )
}
