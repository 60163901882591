import React, { createContext, useState, useContext } from "react"

const LocalEntriesContext = createContext()

export const useLocalEntriesContext = () => useContext(LocalEntriesContext)

export const LocalEntriesProvider = ({ children }) => {
  const [localEntries, setLocalEntries] = useState({})

  return (
    <LocalEntriesContext.Provider value={{ localEntries, setLocalEntries }}>
      {children}
    </LocalEntriesContext.Provider>
  )
}