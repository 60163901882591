export const settingsMigration = {
  0: state => {
    try {
      const coinData = {
        colors: Array(state.quantity.coin).fill(state.colors),
        texts: state.coinTexts,
        images: state.coinImages,
        quantity: Array(state.quantity.coin).fill(1),
        styleQuantity: state.quantity.coin,
        styleIndexSequence: Array(state.quantity.coin)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      const d4Data = {
        contentMode: state.d4.contentMode,
        contents: state.d4.contents,
        quantity: Array(state.d4.quantity).fill(1),
        colors: Array(state.d4.quantity).fill(state.colors),
        styleQuantity: state.d4.quantity,
        styleIndexSequence: Array(state.d4.quantity)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      const d6Data = {
        contentMode: state.d6.contentMode,
        contents: state.d6.contents,
        quantity: Array(state.d6.quantity).fill(1),
        colors: Array(state.d6.quantity).fill(state.colors),
        styleQuantity: state.d6.quantity,
        styleIndexSequence: Array(state.d6.quantity)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      const d8Data = {
        contentMode: state.d8.contentMode,
        contents: state.d8.contents,
        quantity: Array(state.d8.quantity).fill(1),
        colors: Array(state.d8.quantity).fill(state.colors),
        styleQuantity: state.d8.quantity,
        styleIndexSequence: Array(state.d8.quantity)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      const d10Data = {
        contentMode: state.d10.contentMode,
        contents: state.d10.contents,
        quantity: Array(state.d10.quantity).fill(1),
        colors: Array(state.d10.quantity).fill(state.colors),
        styleQuantity: state.d10.quantity,
        styleIndexSequence: Array(state.d10.quantity)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      const d12Data = {
        contentMode: state.d12.contentMode,
        contents: state.d12.contents,
        quantity: Array(state.d12.quantity).fill(1),
        colors: Array(state.d12.quantity).fill(state.colors),
        styleQuantity: state.d12.quantity,
        styleIndexSequence: Array(state.d12.quantity)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      const d20Data = {
        contentMode: state.d20.contentMode,
        contents: state.d20.contents,
        quantity: Array(state.d20.quantity).fill(1),
        colors: Array(state.d20.quantity).fill(state.colors),
        styleQuantity: state.d20.quantity,
        styleIndexSequence: Array(state.d20.quantity)
          .fill(1)
          .flatMap((value, index) =>
            Array.from({ length: value }, () => index)
          ),
      }
      return {
        ...state,
        coin: coinData,
        d4: d4Data,
        d6: d6Data,
        d8: d8Data,
        d10: d10Data,
        d12: d12Data,
        d20: d20Data,
      }
    } catch (error) {
      console.error("Error during migration:", error)
      return state
    }
  },
}
