import { initializeApp } from "firebase/app"
import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getDatabase, connectDatabaseEmulator } from "firebase/database"

// Firebase web config
const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

let instance = null
export let firestore = null
export let storage = null
export let functions = null
export let auth = null
export let database = null

const getFirebase = () => {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = initializeApp(config)
    firestore = getFirestore(instance)
    auth = getAuth(instance)
    functions = getFunctions(instance)
    storage = getStorage(instance)
    database = getDatabase(instance)
    // if (process.env.GATSBY_BUILD_CONTEXT === "development") {
    //   //turn on this for firebase emulator
    //   // Set up emulators
    //   connectFirestoreEmulator(firestore, 'localhost', 8080)
    //   connectAuthEmulator(auth, 'http://localhost:9099')
    //   connectFunctionsEmulator(functions, 'localhost', 5001)
    //   connectStorageEmulator(storage, 'localhost', 9199)
    //   connectDatabaseEmulator(database, 'localhost', 9010)
    // }
    return instance
  }
  return null
}

export default getFirebase
