import React from "react"
import styled, { css } from "styled-components"

const HeadingStyle = css`
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
  align-self: ${props => props.alignSelf};
  @media ${props => props.theme.device.mobile} {
    font-size: ${props => props.fontSizeM};
  }
`

const H1 = styled.h1`
  ${HeadingStyle}
`
const H2 = styled.h2`
  ${HeadingStyle}
`
const H3 = styled.h3`
  ${HeadingStyle}
`
const H4 = styled.h4`
  ${HeadingStyle}
`
const H5 = styled.h5`
  ${HeadingStyle}
`
const H6 = styled.h6`
  ${HeadingStyle}
`

// H1.defaultProps = {
//   fontSize: `${({ theme }) => theme.fontSize.h1}`,
// }
// H2.defaultProps = {
//   fontSize: `${({ theme }) => theme.fontSize.h2}`,
// }
// H3.defaultProps = {
//   fontSize: `${({ theme }) => theme.fontSize.h3}`,
// }
// H4.defaultProps = {
//   fontSize: `${({ theme }) => theme.fontSize.h4}`,
// }
// H5.defaultProps = {
//   fontSize: `${({ theme }) => theme.fontSize.h5}`,
// }
// H6.defaultProps = {
//   fontSize: `${({ theme }) => theme.fontSize.h6}`,
// }

export const Heading = props => {
  switch (props.level) {
    case 1:
      return <H1 {...props}>{props.children}</H1>
    case 2:
      return <H2 {...props}>{props.children}</H2>
    case 3:
      return <H3 {...props}>{props.children}</H3>
    case 4:
      return <H4 {...props}>{props.children}</H4>
    case 5:
      return <H5 {...props}>{props.children}</H5>
    case 6:
      return <H6 {...props}>{props.children}</H6>
    default:
      return <H2 {...props}>{props.children}</H2>
  }
}
