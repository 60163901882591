import React from "react"
import { Box as BoxG, IconButton } from "../commonElements"
import { Star } from "@styled-icons/material-rounded"
import styled from "styled-components"

const Box = styled(BoxG)`
  -webkit-tap-highlight-color: transparent;
`

const StyledStar = styled(Star)`
  & > g {
    display: none;
  }
`

export const Rating = ({
  size,
  scale,
  fillColor,
  strokeColor,
  rating,
  setRating,
  name,
}) => {
  const buttons = []

  const onClick = idx => {
    if (!isNaN(idx)) {
      // allow user to click first icon and set rating to zero if rating is already 1
      if (rating === 1 && idx === 1) {
        setRating(0)
      } else {
        setRating(idx)
      }
    }
  }
  const RatingButton = ({ idx, fill }) => {
    return (
      <IconButton
        aria-label={`Rate ${idx}`}
        height={`${size}px`}
        width={`${size}px`}
        variant="unstyled"
        onClick={() => onClick(idx)}
        icon={
          <StyledStar
            width={`${size}px`}
            height={`${size}px`}
            color={fillColor}
            stroke={strokeColor}
            onClick={onClick}
            fillOpacity={fill ? "100%" : "0"}
          />
        }
        _focus={{ outline: 0 }}
      />
    )
  }

  for (let i = 1; i <= scale; i++) {
    buttons.push(<RatingButton key={i} idx={i} fill={i <= rating} />)
  }

  return (
    <Box flexDirection="row" justifyContent="center" minWidth="28rem">
      <input name={name} type="hidden" value={rating} />
      {buttons}
    </Box>
  )
}

Rating.displayName = "Rating"
