import { createReducer } from "../reducerUtil"
import {
    UPDATE_EMBED_TOOL,
    UPDATE_EMBED_BACKGROUND_COLOR,
    UPDATE_EMBED_COLORS,
    UPDATE_EMBED_SOUND,
    UPDATE_EMBED_HEIGHT,
    UPDATE_EMBED_QUANTITY,
    UPDATE_EMBED_CONTENT_MODE,
    UPDATE_EMBED_CONTENT,
    UPDATE_EMBED_TEXTS,
    UPDATE_EMBED_ALL_SETTINGS,
    UPDATE_EMBED_DIRECTION,
} from "./constants"

const initialState = {
    tool: "coin",
    backgroundColor: "#FFFFFF",
    colors: ["#59569D", "#F25292"],
    sound: {
        on: true,
    },
    height: "430",
    coin: {
        contentMode: ["TEXTS"],
        texts: [{
            heads: "HEADS",
            tails: "TAILS",
        }],
        quantity: 1,
    },
    d6: {
        contentMode: ["SHAPES"],
        contents: [{ shapes: "dots" }],
        quantity: 1,
    },
    d4: {
        contentMode: ["SHAPES"],
        contents: [{ shapes: "numbers" }],
        quantity: 1,
    },
    d8: {
        contentMode: ["SHAPES"],
        contents: [{ shapes: "numbers" }],
        quantity: 1,
    },
    d10: {
        contentMode: ["SHAPES"],
        contents: [{ shapes: "numbers" }],
        quantity: 1,
    },
    d12: {
        contentMode: ["SHAPES"],
        contents: [{ shapes: "numbers" }],
        quantity: 1,
    },
    d20: {
        contentMode: ["SHAPES"],
        contents: [{ shapes: "numbers" }],
        quantity: 1,
    },
    direction: 0,
}

export const updateEmbedTool = (state, payload) => {
    return {
        ...state,
        tool: payload.tool
    }
}

export const updateEmbedBackgroundColor = (state, payload) => {
    return {
        ...state,
        backgroundColor: payload.color
    }
}

export const updateEmbedColors = (state, payload) => {
    return {
        ...state,
        colors: payload.colors,
    }
}

export const updateEmbedSound = (state, payload) => {
    return {
        ...state,
        sound: {
            on: payload.on,
        },
    }
}

export const updateEmbedHeight = (state, payload) => {
    return {
        ...state,
        height: payload.height
    }
}

export const updateEmbedQuantity = (state, payload) => {
    return {
        ...state,
        [payload.type]: {
            ...state[payload.type],
            quantity: payload.number,
        },
    }
}

export const updateEmbedContentMode = (state, payload) => {
    return {
        ...state,
        [payload.type]: {
            ...state[payload.type],
            contentMode: payload.mode,
        },
    }
}

export const updateEmbedContent = (state, payload) => {
    return {
        ...state,
        [payload.type]: {
            ...state[payload.type],
            contents: payload.content,
        },
    }
}

export const updateEmbedTexts = (state, payload) => {
    return {
        ...state,
        [payload.type]: {
            ...state[payload.type],
            texts: payload.texts,
        },
    }
}

export const updateEmbedAllSettings = (state, payload) => {
    return {
        ...state,
        [payload.settings.tool]: {
            ...state[payload.settings.tool],
            contentMode: payload.settings[payload.settings.tool].contentMode,
            texts: payload.settings[payload.settings.tool]?.texts,
            contents: payload.settings[payload.settings.tool]?.contents,
            quantity: payload.settings[payload.settings.tool].quantity,
        },
        tool: payload.settings.tool,
        backgroundColor: payload.settings.backgroundColor,
        colors: payload.settings.colors,
        sound: payload.settings.sound,
        direction: payload.settings?.direction
    }
}

export const updateEmbedDirection = (state, payload) => {
    return {
        ...state,
        direction: payload.direction
    }
}

export default createReducer(initialState, {
    [UPDATE_EMBED_TOOL]: updateEmbedTool,
    [UPDATE_EMBED_BACKGROUND_COLOR]: updateEmbedBackgroundColor,
    [UPDATE_EMBED_COLORS]: updateEmbedColors,
    [UPDATE_EMBED_SOUND]: updateEmbedSound,
    [UPDATE_EMBED_HEIGHT]: updateEmbedHeight,
    [UPDATE_EMBED_QUANTITY]: updateEmbedQuantity,
    [UPDATE_EMBED_CONTENT_MODE]: updateEmbedContentMode,
    [UPDATE_EMBED_CONTENT]: updateEmbedContent,
    [UPDATE_EMBED_TEXTS]: updateEmbedTexts,
    [UPDATE_EMBED_ALL_SETTINGS]: updateEmbedAllSettings,
    [UPDATE_EMBED_DIRECTION]: updateEmbedDirection,
})
