import React, { createContext, useState, useContext } from "react"
import Toast from "./toast"

const ToastContext = createContext()

export const useToastContext = () => useContext(ToastContext)

export const ToastProvider = ({ children }) => {
  const [toastText, setToastText] = useState("")

  const setToast = text => {
    setToastText(text)
    setTimeout(() => {
      setToastText("")
    }, 2000)
  }
  return (
    <ToastContext.Provider value={{ toastText, setToast }}>
      {children}
      <Toast text={toastText} />
    </ToastContext.Provider>
  )
}
