import React from "react"
import { Box } from "../commonElements"
import styled from "styled-components"
import { rgba } from "polished"

const Container = styled(Box)`
  background-color: ${props => rgba(props.theme.colors.backdrop, 0.5)};
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
`

const Backdrop = ({ children, onClick }) => {
  return (
    <Container
      alignSelf="stretch"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      onClick={onClick}
    >
      {children}
    </Container>
  )
}

export default Backdrop
