import styled from "styled-components"
export const Box = styled.div`
  /* position: relative; */
  display: flex;
  position: ${props => props.positionBox};
  flex-direction: ${props => props.flexDirection};
  background: ${props => props.background};
  background-color: ${props =>
    props.theme.colors[props.backgroundColor]
      ? props => props.theme.colors[props.backgroundColor]
      : props.backgroundColor};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  min-width: ${props => props.minWidth};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  flex: ${props => props.flex};
  justify-content: ${props => props.justifyContent};
  align-content: ${props => props.alignContent};
  align-items: ${props => props.alignItems};
  align-self: ${props => props.alignSelf};
  flex-wrap: ${props => props.flexWrap};
  padding: ${props => props.padding};
  padding-top: ${props => props.pt};
  padding-left: ${props => props.pl};
  padding-bottom: ${props => props.pb};
  padding-right: ${props => props.pr};
  margin: ${props => props.margin};
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  margin-right: ${props => props.mr};
  top: ${props => props.topP};
  right: ${props => props.rightP};
  bottom: ${props => props.bottomP};
  left: ${props => props.leftP};
  z-index: ${props => props.zIndex};
  white-space: ${props => props.whiteSpace};
  & > * {
    margin-right: ${props => props.flexDirection === "row" && props.gap};
    margin-bottom: ${props => props.flexDirection === "column" && props.gap};
    &:last-child {
      margin-right: ${props => props.gap && "0px"};
      margin-bottom: ${props => props.gap && "0px"};
    }
  }
`
Box.defaultProps = {
  // display: "flex",
  minHeight: 0,
  minWidth: 0,
  maxWidth: "100%",
  flexDirection: "column",
  // backgroundColor: "transparent",
  // width: "auto",
  // height: "auto",
  // flex: "none",
  // justifyContent: "flex-start",
  // alignItems: "flex-start",
  // flexWrap: "nowrap",
}
