import React, { createContext, useState, useContext } from "react"

const LocalContentContext = createContext()

export const useLocalContentContext = () => useContext(LocalContentContext)

export const LocalContentProvider = ({ children }) => {
  const [localTextContent, setLocalTextContent] = useState([])
  const [localImageContent, setLocalImageContent] = useState([])

  return (
    <LocalContentContext.Provider value={{ localTextContent, setLocalTextContent, localImageContent, setLocalImageContent }}>
      {children}
    </LocalContentContext.Provider>
  )
}