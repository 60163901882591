import React from "react"
import styled from "styled-components"

const Box = styled.label`
  input {
    display: none;
  }
  input + div {
    position: relative;
    width: ${props => props.size};
    height: ${props => props.size};
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      width: ${props => props.size};
      height: ${props => props.size};
      border: 1px solid ${props => props.theme.colors.border};
      background-color: ${props => props.backgroundColor};
      border-radius: 3px;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 7px;
      height: 12px;
      border: solid ${props => props.theme.colors.accent};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  /* > input:not(:checked) + div {
      &:after {
        opacity: 0; 
        transform: scale(0); 
      }
  }
  > input:disabled:not(:checked) + div {
      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
  } */
  > input:checked + div {
    &:after {
      /* opacity: 1; 
      transform: scale(1);  */
      display: block;
    }
  }
  /* > input:disabled:checked + div {
    &:after {
      color: #999;
    }
  }
  > input:disabled + div {
    color: #aaa;
  } */
  /* > input:checked:focus + div, input:not(:checked):focus + div {
    &:before {
      border: 1px dotted blue;
    }
  } */
`

export const CheckBox = ({ onChange = null, checked, label, backgroundColor = "transparent" }) => {
  return (
    <Box size="20px" fontSize="17px" backgroundColor={backgroundColor}>
      <input
        readOnly={onChange === null ? true : false}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div>{label}</div>
    </Box>
  )
}
