const deviceSize = {
  mobileL: 425,
  mobile: 828,
  tablet: 1240,
  laptop: 1240,
}

export const device = {
  mobileL: `screen and (max-width: ${deviceSize.mobileL}px)`,
  mobile: `screen and (max-width: ${deviceSize.mobile}px)`,
  tablet: `screen and (max-width: ${deviceSize.tablet}px)`,
  laptop: `screen and (min-width: ${deviceSize.laptop}px)`,
}
