import React from "react"
import styled, { keyframes } from "styled-components"

const ripple = keyframes`
  
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }
  
  50% { 
    box-shadow: 0px 0px 0px ${props => props.rippleSize} rgba(0, 0, 0, 0.1);
  }
  
  100% {
    box-shadow: 0px 0px 0px ${props => props.rippleSize} rgba(0, 0, 0, 0);
  }
`

const Box = styled.label`
    /* margin: 16px 0; */
    display: flex;
    input[type="radio"] {
        display: none;
      
        &:checked + div:before {
            border-color: ${props => props.theme.colors.accent};
            animation: ${ripple} 0.2s linear forwards;   
        }
      
        &:checked + div:after {
            transform: scale(1);
        }
    }
    
    div {
        display: inline-block;
        height:${props => props.size};
        width:${props => props.size};
        position: relative;
        padding: 0;
        /* padding: 0 calc((${props => props.size} + 10px)); */
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
      
        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
      
        &:before {
            left: 0;
            top: 0;
            width: ${props => props.size};
            height: ${props => props.size};
            border: 2px solid ${props => props.theme.colors.border};
        }
      
        &:after {
            top: calc(${props => props.size} / 2 - ${props =>
  props.checkSize} / 2);
            left: calc(${props => props.size} / 2 - ${props =>
  props.checkSize} / 2);
            width:${props => props.checkSize};
            height:${props => props.checkSize};
            transform: scale(0);
            background:${props => props.theme.colors.accent};
        }
    }
`

export const RadioButton = ({ checked, onChange = null, label }) => {
  return (
    <Box size="18px" checkSize="10px" rippleSize="15px">
      <input
        readOnly={onChange === null ? true : false}
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <div>{label}</div>
    </Box>
  )
}
