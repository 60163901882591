import { combineReducers } from "redux"
import { persistReducer, createMigrate } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import statsReducer from "./stats/reducer"
import challengeReducer from "./challenge/reducer"
import modalReducer from "./modal/reducer"
import settingsReducer from "./settings/reducer"
import shareReducer from "./shareLink/reducer"
import coin2048Reducer from "./coin2048/reducer"
import tarotReducer from "./tarot/reducer"
import embedReducer from "./embedTool/reducer"
import embedLinkReducer from "./embedLink/reducer"
import entriesReducer from "./entries/reducer"
import generalReducer from "./general/reducer"
import authReducer from "./auth/reducer"
import commonReducer from "./common/reducer"
import { settingsMigration } from "../utils/persistMigration"

const persistConfigChallenge = {
  key: "challenge",
  storage,
  whitelist: ["current"],
}

const persistConfigSettings = {
  key: "settings",
  storage,
  version: 0,
  whitelist: [
    "coin",
    "d6",
    "d4",
    "d8",
    "d10",
    "d12",
    "d20",

    // start of old state
    "coinTexts",
    "coinImages",
    "quantity",
    "colors",
    // end of old state
  ],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(settingsMigration, { debug: false }),
}

const persistConfigStats = {
  key: "stats",
  storage,
  whitelist: ["my", "statsIndex"],
}

const persistConfigCoin2048 = {
  key: "coin2048",
  storage,
  whitelist: ["accumScore"],
}

const persistConfigTarot = {
  key: "tarot",
  storage,
  whitelist: ["type", "text", "question"],
}

const persistConfigGeneral = {
  key: "general",
  storage,
  whitelist: ["colors", "sound", "backgroundColor", "effect", "pronunciation"],
  stateReconciler: autoMergeLevel2,
}

const persistConfigEntries = {
  key: "entries",
  storage,
  whitelist: ["number"],
  stateReconciler: autoMergeLevel2,
}

const rootReducer = combineReducers({
  stats: persistReducer(persistConfigStats, statsReducer),
  challenge: persistReducer(persistConfigChallenge, challengeReducer),
  modal: modalReducer,
  settings: persistReducer(persistConfigSettings, settingsReducer),
  share: shareReducer,
  coin2048: persistReducer(persistConfigCoin2048, coin2048Reducer),
  tarot: persistReducer(persistConfigTarot, tarotReducer),
  embed: embedReducer,
  embedLink: embedLinkReducer,
  entries: persistReducer(persistConfigEntries, entriesReducer),
  general: persistReducer(persistConfigGeneral, generalReducer),
  auth: authReducer,
  common: commonReducer,
})

export default rootReducer
