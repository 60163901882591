export const UPDATE_EMBED_TOOL = "UPDATE_EMBED_TOOL"
export const UPDATE_EMBED_BACKGROUND_COLOR = "UPDATE_EMBED_BACKGROUND_COLOR"
export const UPDATE_EMBED_COLORS = "UPDATE_EMBED_COLORS"
export const UPDATE_EMBED_SOUND = "UPDATE_EMBED_SOUND"
export const UPDATE_EMBED_HEIGHT = "UPDATE_EMBED_HEIGHT"
export const UPDATE_EMBED_QUANTITY = "UPDATE_EMBED_QUANTITY"
export const UPDATE_EMBED_CONTENT_MODE = "UPDATE_EMBED_CONTENT_MODE"
export const UPDATE_EMBED_CONTENT = "UPDATE_EMBED_CONTENT"
export const UPDATE_EMBED_TEXTS = "UPDATE_EMBED_TEXTS"
export const UPDATE_EMBED_ALL_SETTINGS = "UPDATE_EMBED_ALL_SETTINGS"
export const UPDATE_EMBED_DIRECTION = "UPDATE_EMBED_DIRECTION"