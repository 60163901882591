import React from "react"
import styled, { keyframes } from "styled-components"

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Spinner = styled.div`
  display: inline-block;
  width: 18px; /* Final icon size */
  height: 18px; /* Final icon size */
  border: 3px solid ${props => props.theme.colors.text}; /* Thickness and emptyColor */
  border-radius: 50%;
  border-top-color: ${props =>
    props.theme.colors.scrollbar}; /* Color of the spinner */
  animation: ${spin} 0.65s linear infinite; /* Speed */
`
