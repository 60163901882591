import React, { createContext, useState, useContext } from "react"
import PageLoading from "./pageLoading"

const PageLoadingContext = createContext()

export const usePageLoadingContext = () => useContext(PageLoadingContext)

export const PageLoadingProvider = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(false)

  return (
    <PageLoadingContext.Provider value={{ pageLoading, setPageLoading }}>
      {children}
      {pageLoading && <PageLoading />}
    </PageLoadingContext.Provider>
  )
}
