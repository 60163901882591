import React, {useState, useEffect} from 'react'
import {Box} from './box'
import styled from 'styled-components'

const Slider = styled(Box)`
    display: inline-flex;
    position: relative;
`

const DotHolders = styled.div`
    display: inline-flex;
`

const DotHolderC = styled.div`
    width: ${props=>`${props.size}px`};
    height: ${props=>`${props.size}px`};
    border-radius: 50%;
    background-color: #4A4A4A;
    margin-right: ${props=>`${0.3*props.size}px`};
    cursor: ${props=>props.clickable ? "pointer": "auto"};

    &:last-child {
        margin-right: 0;
    }
`

const DotC = styled.div`
    width: ${props=>`${0.8*props.size}px`};
    height: ${props=>`${0.8*props.size}px`};
    border-radius: 50%;
    background-color: ${props=>props.theme.colors.accent};
    position: absolute;
    left: ${props=>`${0.1*props.size}px`};
    top: 0px;
    bottom: 0px;
    margin: auto;
    transition: left 0.2s ease-in;
    left: ${({size, position})=>`${(0.1 * size) + position * (size + 0.3 * size)}px`};
`

export const SliderDots = (props) => {
    const [position, setPosition] = useState(props.position)

    useEffect(()=>{
        setPosition(props.position)
    }, [props.position])

    const updatePosition = (position) => {
        if (props.clickable) {
            setPosition(position)
            if (props.positionChangeListener) {
                props.positionChangeListener(position)
            }
        }
    }
    
    const generateDotHolders= () => {
        let dotHolders = []
        for (let i = 0; i < props.length; i++) {
            dotHolders.push(<DotHolder key={`sliderDots${i}`} position={i} clickable={props.clickable} size={props.size} updatePosition={updatePosition} />)
        }
        return dotHolders
    }
    const dotHolders = generateDotHolders()
    return (
        <Slider {...props}>
            <DotHolders>
                { dotHolders }
            </DotHolders>
            <Dot position={position} size={props.size}/>
        </Slider>
    )
}  

const DotHolder = (props) => {
    return (
        <DotHolderC size={props.size} clickable={props.clickable} onClick={() => props.updatePosition(props.position)}></DotHolderC>
    )
}

const Dot = (props) => {
    return(
        <DotC position={props.position} size={props.size}></DotC>
    )
}

SliderDots.defaultProps = {
    position: 0,
    length: 3,
    size: 10,
    clickable: true,
    positionChangeListener: null
}

// export const SliderDots