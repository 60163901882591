import styled from "styled-components"

export const Anchor = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.accent};
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
  -webkit-tap-highlight-color: transparent;
`
