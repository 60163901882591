export const colors = {
  primary: "#59569D",
  secondary: "#F25292",
  // accent: "#F7B71D",
  transparent: "transparent",
  accent: "#FFA000",
  text: "#3D3D3D",
  placeholder: "#bbbbbb",
  border: "#bbbbbb",
  borderCoin: "#eeeeee",
  borderCoinDark: "#111111",
  background: "#EEEEEE",
  white: "#fff",
  scrollbar: "#bbb",
  backdrop: "#000",
  bgLuck: "#bbb",
  mainInput: "#D3D3D3",
  bgToolTip: "rgba(0,0,0,0.7)",
  reject: "#D0021B",
}
