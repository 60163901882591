import { Box } from "./box"
import styled from "styled-components"

export const GlassBox = styled(Box)`
  /* background-color: white;
  border-radius: 10px; */

  /* backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); */
  background-color: rgba(255, 255, 255);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`
