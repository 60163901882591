import styled from "styled-components"

export const Text = styled.span`
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  color: ${props => props.theme.colors[props.color]};
  font-weight: ${props => props.fontWeight};
  margin-top: ${props => props.mt};
  width: ${props => props.width};
  align-self: ${props => props.alignSelf};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
`

// Text.defaultProps = {
//   color: "text",
// }
