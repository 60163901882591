import React from "react"
import { Box, Text } from "../../commonElements"
import styled from "styled-components"

const ToastBox = styled(Box)`
  top: ${props => (props.active ? "5rem" : "-5rem")};
  position: fixed;
  transition: top 350ms;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;
`

const Toast = ({ text, backgroundColor = "bgToolTip" }) => {
  return (
    <ToastBox
      backgroundColor={backgroundColor}
      positionBox="absolute"
      padding=".8rem 2rem"
      borderRadius="50px"
      zIndex={99}
      active={text !== ""}
    >
      <Text color="white">{text}</Text>
    </ToastBox>
  )
}

export default Toast
