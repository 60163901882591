import { createReducer } from "../reducerUtil"
import { SET_CHALLENGE, SET_CUID_STATUS_CHALLENGE, SET_GUESS_CHALLENGE } from "./constants"
import cuid from "cuid"

const initialState = {
  current: { value: "none", label: "Just Flip" },
  cuidChallenge: cuid(),
  statusChallenge: "heads",
  guessChallenge: "",
}

export const setChallenge = (state, payload) => {
  return {
    ...state,
    current: payload.challenge,
  }
}

export const setCuidStatusChallenge = (state, payload) => {
  return {
    ...state,
    cuidChallenge: cuid(),
    statusChallenge: payload.status,
  }
}

export const setGuessChallenge = (state, payload) => {
  return {
    ...state,
    guessChallenge: payload.guess,
  }
}

export default createReducer(initialState, {
  [SET_CHALLENGE]: setChallenge,
  [SET_CUID_STATUS_CHALLENGE]: setCuidStatusChallenge,
  [SET_GUESS_CHALLENGE]: setGuessChallenge,
})
