import { KEEP_EMBED_LINK } from "./constants"
import { createReducer } from "../reducerUtil"

const initialState = {
  // createdCount: 0,
  // lastCreate: 0,
  lastEmbedPage: null,
  settings: null,
  path: "",
}

export const keepEmbedLink = (state, payload) => {
  return {
    lastEmbedPage: payload.embedPage,
    // lastCreate: payload.lastCreate,
    settings: payload.settings,
    // createdCount: payload.createdCount,
    path: payload.path,
  }
}

export default createReducer(initialState, {
  [KEEP_EMBED_LINK]: keepEmbedLink,
})
