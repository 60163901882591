import { createReducer } from "../reducerUtil"
import {
  UPDATE_ENTRIES_STATE,
  UPDATE_ENTRIES_HISTORY,
  UPDATE_ALL_ENTRIES,
} from "./constants"

const initialState = {
  number: {
    formula: "(1;10)",
    sort: "R", // R: Randomly, LH: Low-High, HL: High-Low
    display: ["number", "numeral"],
    quantity: 0,
    list: [],
    result: [],
    index: 0,
    repeat: false,
    all: true,
  },
  history: [],
}

export const updateEntriesState = (state, payload) => {
  return {
    ...state,
    [payload.type]: {
      ...state[payload.type],
      [payload.text]: payload.value,
    },
  }
}

export const updateEntriesHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const updateAllEntries = (state, payload) => {
  switch (payload.page) {
    case "number":
      return {
        ...state,
        [payload.page]: {
          ...state[payload.page],
          formula: payload.settings.data.formula,
          sort: payload.settings.data.sort,
          display: payload.settings.data.display,
          quantity: payload.settings.data.quantity,
          list: payload.settings.data.list,
          result: payload.settings.data.result,
          index: -1,
          repeat: payload.settings.data.repeat,
          all: payload.settings.data.all,
        },
      }
  }
}

export default createReducer(initialState, {
  [UPDATE_ENTRIES_STATE]: updateEntriesState,
  [UPDATE_ENTRIES_HISTORY]: updateEntriesHistory,
  [UPDATE_ALL_ENTRIES]: updateAllEntries,
})
