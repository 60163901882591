import { createReducer } from "../reducerUtil"
import {
  SET_WEBSITE_MODE,
  SET_CURRENT_FILE_KEY,
  SET_DATA_STATUS,
} from "./constants"

const initialState = {
  websiteMode: "Normal", //Normal, ShareViewOnly
  currentFileInfo: null,
  dataStatus: "Unchanged", //Unchanged | Modified
}

export const setWebsiteMode = (state, payload) => {
  return {
    ...state,
    websiteMode: payload.mode,
  }
}

export const setCurrentFileInfo = (state, payload) => {
  return {
    ...state,
    currentFileInfo: payload
      ? {
          id: payload.id,
          path: payload.path,
        }
      : null,
  }
}

export const setDataStatus = (state, payload) => {
  return {
    ...state,
    dataStatus: payload.status,
  }
}

export default createReducer(initialState, {
  [SET_WEBSITE_MODE]: setWebsiteMode,
  [SET_CURRENT_FILE_KEY]: setCurrentFileInfo,
  [SET_DATA_STATUS]: setDataStatus,
})
