/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "firebase/database"
import "./src/theme/styles.css"
import { wrapRootElement as wrap } from "./src/layout/rootWrapper"
import { wrapPageElement as wrapPage } from "./src/layout/pageWrapper"
import getFirebase from "./src/utils/firebase"
getFirebase()
export const wrapRootElement = wrap
export const wrapPageElement = wrapPage
