import { SIGN_IN_USER, SIGN_OUT_USER } from "./constants"
import { createReducer } from "../reducerUtil"

const initialState = {
  authenticated: null, //must use null instead of false.. because useeffect will trigger once before the value change to false.
  currentUser: null,
}

export const signInUser = (state, payload) => {
  return {
    ...state,
    authenticated: true,
    currentUser: payload.user,
  }
}

export const signOutUser = (state, payload) => {
  return {
    ...state,
    authenticated: false,
    currentUser: null,
  }
}

export default createReducer(initialState, {
  [SIGN_IN_USER]: signInUser,
  [SIGN_OUT_USER]: signOutUser,
})
