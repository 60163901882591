import { createReducer } from "../reducerUtil"
import {
  UPDATE_2048_SCORES,
} from "./constants"

const initialState = {
  accumScore: 0,
  previousScore: 0,
}

export const update2048Scores = (state, payload) => {
  return {
    accumScore: payload.accumScore,
    previousScore: payload.previousScore,
  }
}

export default createReducer(initialState, {
  [UPDATE_2048_SCORES]: update2048Scores,
})
