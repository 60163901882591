import React from "react"
import Backdrop from "../../modal/Backdrop"
import { Box, Loader } from "../../commonElements"

const PageLoading = () => {
  return (
    <Backdrop>
      <Box
        width="13rem"
        height="13rem"
        backgroundColor="white"
        alignItems="center"
        justifyContent="center"
        borderRadius="1rem"
      >
        <Loader />
      </Box>
    </Backdrop>
  )
}

export default PageLoading
