import { createReducer } from "../reducerUtil"
import {
  DISPLAY_MODAL,
  TRIGGER_RESULT_RESET,
  OPEN_SHARE_MODAL,
  OPEN_FILE_MODAL,
  OPEN_EMBED_MODAL,
} from "./constants"

const initialState = {
  result: null,
  resetTriggered: false,
  shareModalOpened: false,
  fileModalData: null,
  embedModalOpened: false,
}

export const displayModal = (state, payload) => {
  return {
    ...state,
    result: payload.result,
  }
}

export const triggerResultReset = (state, payload) => {
  return {
    ...state,
    resetTriggered: payload.trigger,
  }
}

export const openShareModal = (state, payload) => {
  return {
    ...state,
    shareModalOpened: payload.open,
  }
}

export const openFileModal = (state, payload) => {
  return {
    ...state,
    fileModalData: payload
      ? {
          mode: payload.mode, // Replace | Delete | New | Open
          page: payload.page,
          path: payload.path,
          id: payload.id,
        }
      : null,
  }
}

export const openEmbedModal = (state, payload) => {
  return {
    ...state,
    embedModalOpened: payload.open,
  }
}

export default createReducer(initialState, {
  [DISPLAY_MODAL]: displayModal,
  [TRIGGER_RESULT_RESET]: triggerResultReset,
  [OPEN_SHARE_MODAL]: openShareModal,
  [OPEN_FILE_MODAL]: openFileModal,
  [OPEN_EMBED_MODAL]: openEmbedModal,
})
