import React from "react"
import styled from "styled-components"
import { Trash } from "@styled-icons/fa-solid"
import { rgba } from "polished"

import { Box } from "./box"
import { Text } from "./text"
import { Image } from "./image"
import { IconButton } from "./iconButton"
import { Button } from "./button"

const InputLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.mainInput};
  border-radius: 3px;
  padding: ${props => (props.size === "normal" ? ".6rem" : ".3rem")};
  font-weight: 700;
  line-height: 8px;
  /* padding: ${props =>
    props.size === "medium" ? ".8rem 2rem" : ".8rem 3rem"}; */
  text-align: center;
  &:hover {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.colors.mainInput};
  }
  & > svg {
    margin-right: 3px;
  }
`

const InputData = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`

const ImageOverlay = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background-color: ${props => `${rgba("#000", 0.5)}`};
`

const ImageBox = styled(Box)`
  overflow: hidden;
  /* & > ${ImageOverlay} {
      display: none;
    }
  &:hover {
    & > ${ImageOverlay} {
      display: flex;
    }
  } */
`

export const ImageInput = ({
  label,
  src,
  id,
  onImageChange,
  imageRemove,
  color,
  onEdit,
  flex,
  height,
  size = "normal",
  allowEdit = true,
  cropShape = "round",
}) => {
  return (
    <Box height={height} flex={flex} gap=".5rem">
      <Box
        flexDirection="row"
        gap=".7rem"
        alignItems="center"
        positionBox="relative"
      >
        <Text fontSize={size === "normal" ? "1.6rem" : "1.4rem"}>{label}</Text>
        <InputLabel htmlFor={id} size={size}>
          +
        </InputLabel>
        <InputData
          id={id}
          onChange={onImageChange}
          onClick={event => {
            event.target.value = null
          }}
          type="file"
          accept="image/*"
        />
        {src && (
          <IconButton
            icon={<Trash size={size === "normal" ? 16 : 14} />}
            onClick={imageRemove}
          />
        )}
      </Box>

      {src && (
        <Box mt={size === "normal" ? ".5rem" : "0rem"}>
          <ImageBox
            width={size === "normal" ? "8.5rem" : "6rem"}
            height={size === "normal" ? "8.5rem" : "6rem"}
            borderRadius={cropShape === "round" ? "50%" : "15%"}
            border={`2px solid ${color}`}
            positionBox="relative"
          >
            <Image
              width="100%"
              height="100%"
              borderRadius={cropShape === "round" ? "50%" : "0%"}
              src={src}
              alt={`${label} Image Input`}
            />
            {allowEdit && (
              <ImageOverlay alignItems="center" justifyContent="center">
                <Button
                  pattern="text"
                  color="white"
                  fontSize="1.2rem"
                  onClick={onEdit}
                >
                  Edit
                </Button>
              </ImageOverlay>
            )}
          </ImageBox>
        </Box>
      )}
    </Box>
  )
}
