import styled from "styled-components"

export const Paragraph = styled.p`
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
  margin-bottom: ${props => props.mb};
  /* font-weight: ${props => props.fontWeight};
  line-height: 1.2;
  margin-bottom: 1.2rem; */
  margin-top: ${props => props.mt};
`

// Paragraph.defaultProps = {
//   // fontSize: "inherit",
// }
